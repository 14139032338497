import React, { useEffect } from 'react';
import { Box, Card, TextField, Button, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as YUP from 'yup';
import { useCryptoHubDashboardStyles } from './CryptohubStyles';
import { useCryptohubWebhookPutRequest, useCryptohubWebhookRequest } from './CryptoHubDashboardQueries';
import Loader from 'app/components/unsorted/Loader';
import { useNotification } from 'app/providers';

const schema = YUP.object({
    web_hook_url: YUP.string().nullable(),
    quantity: YUP.number()
        .typeError("Quantity should be a number.")
        .min(1, "Quantity cannot be negative.")
        .required("Quantity is required"),
}).required();

export const CryptoWebHooks = () => {
    const { classes } = useCryptoHubDashboardStyles();
    const webhookQuery = useCryptohubWebhookRequest();
    const webhookPut = useCryptohubWebhookPutRequest();
    const notification = useNotification();
    const [editMode, setEditMode] = React.useState(false);

    const refreshForm = webhookQuery.result ? true : false;

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            web_hook_url: null,
            quantity: 0,
        },
        mode: 'onBlur',
    });

    useEffect(() => {
        reset({
            web_hook_url: webhookQuery.result?.new_transaction_webhook,
            quantity: webhookQuery.result?.minimum_amount_for_new_transaction,
        });
    }, [refreshForm]);

    const onSubmitHandler = (values: any) => {
        if(editMode){
            webhookPut.EXECUTE({
                payload: {
                    new_transaction_webhook: (values.web_hook_url as String)?.length > 0 ? values.web_hook_url : null,
                    minimum_amount_for_new_transaction: values.quantity,
                },
                onSuccessFn: () => {
                    notification.success("Ok");
                    webhookQuery.refetch();
                    setEditMode(false);
                },
                onErrorFn: (error, message) => {
                    notification.error(message);
                }
            });
        }
        else{
            setEditMode(true);
        }
    };

    if (webhookQuery.isResolving) {
        return <Loader isLoading={true} />;
    }

    return (
        <Box>
            <Card className={classes.mainCard}>
                <Typography variant="h5" fontWeight="bold">
                    Web Hooks
                </Typography>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Controller
                        name="web_hook_url"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Web Hook URL"
                                variant="outlined"
                                fullWidth
                                error={!!errors.web_hook_url}
                                helperText={errors.web_hook_url ? errors.web_hook_url.message : ''}
                                margin="normal"
                                disabled={!editMode}
                            />
                        )}
                    />
                    <Controller
                        name="quantity"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Minimum Amount for New Transaction(In EURO)"
                                variant="outlined"
                                fullWidth
                                error={!!errors.quantity}
                                helperText={errors.quantity ? errors.quantity.message : ''}
                                margin="normal"
                                type="number"
                                disabled={!editMode}
                            />
                        )}
                    />

                    <Button type="submit" variant="contained" color="primary" sx={{
                        marginTop: '1rem',
                        width: '100%',
                    }}>
                        {
                            editMode ? 'Update' : 'Edit'
                        }
                    </Button>
                </form>
            </Card>
        </Box>
    );
};
