import React from "react";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { Dayjs } from "dayjs";
import Loader from "app/components/unsorted/Loader";
import { SearchInput } from "app/components/inputs";
import { useCountryList } from "app/queries/InputDataQueries";
import CountryFlag from "react-country-flag";
import { RQ } from "app/hooks";
import { createAPIURL } from "app/config";
import { T_UseCryptohubPaymentState } from "./CryptohubPaymentHooks";
import { useLoader, useNotification } from "app/providers";
import { useCryptohubTransactionRequest } from "./CryptohubPaymentQueries";
import {useTranslation} from "react-i18next";

interface FormValues {
    countryOfResidence: string;
    placeOfBirth: string;
    address: string;
    mobileNumber: string;
    countryCode: string;
    incomeSourece: number;
}

interface T_PersonalDetailsFormProps {
    paymentState: T_UseCryptohubPaymentState;
    classes: any;
}

const formSchema = Yup.object().shape({
    countryOfResidence: Yup.string().required("Country of residence is required"),
    placeOfBirth: Yup.string().required("Place of birth is required"),
    address: Yup.string().required("Address is required"),
    mobileNumber: Yup.string().optional(),
    countryCode: Yup.string().optional(),
    incomeSourece: Yup.number().required("Income source is required"),
});

const PersonalDetailsForm = ({paymentState, classes} : T_PersonalDetailsFormProps) => {
    const { t } = useTranslation();
    const notification = useNotification();
    const loader = useLoader();

    const { result: countryList, isLoading : countryListRefecting } = useCountryList();
    const countries = countryList ? countryList : [];

    const incomeSources = [
        {id: 1, name: "Salary or Work Income"},
        {id: 2, name: "Personal Saving"},
        {id: 3, name: "Pension"},
        {id: 4, name: "Legal Compensation"},
        {id: 5, name: "Capital Gains"},
        {id: 6, name: "Gifts or Inheritance"},
    ];

    const transactionDetails = useCryptohubTransactionRequest(paymentState.transactionId);

    const postPersonalDetails = RQ.usePostRequest({
        url: createAPIURL('customers', [transactionDetails.result?.sender_id, 'simple-kyc']),
    })


    const updatePaymentState = () => {
        paymentState.update({isSimpleKycCompleted: true});
    }

    if(transactionDetails.isResolved && !transactionDetails.result?.kyc_status.simple_kyc_required) {
        paymentState.update({isSimpleKycCompleted: true, isSimpleKycOtpVerified: true});
        return <Loader isLoading={true} />;
    }

    const handleSubmit = async (values: FormValues) => {
        loader.show();
            
        const payload = {
            "mobile_no": `${countries.find((country) => country.id === values.countryCode)?.phone_code}${values.mobileNumber}`,
            "country_of_residence": countries.find((country) => country.id === values.countryOfResidence)?.name,
            "place_of_birth": countries.find((country) => country.id === values.placeOfBirth)?.name,
            "source_of_fund": values.incomeSourece,
            "address": values.address,
        }

        await postPersonalDetails.EXECUTE({
            payload,
            onSuccessFn: (data, message) => {
                notification.success(message);
                updatePaymentState();
            },
            onErrorFn: (data, message) => {
                notification.error(message);
            }
        })

        loader.hide();
    };

    if(countryListRefecting || transactionDetails.isResolving) {
        return <Loader isLoading={true} />;
    }

    return (
        <Box className={classes.mainBoxForm}>
            <Typography variant="h4" gutterBottom>
                {t("Personal Details")}
            </Typography>
            <Formik
                initialValues={
                    {
                        countryOfResidence: "",
                        placeOfBirth: "",
                        address: "",
                        mobileNumber: "",
                        countryCode: "",
                        incomeSourece: 1,
                    } as FormValues
                }
                validationSchema={formSchema}
                onSubmit={(values: FormValues) => {
                    handleSubmit(values);
                }}
            >
                {({
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                    setFieldValue,
                }) => (
                    <Grid container>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <SearchInput
                                options={countries}
                                label={t("Country of Residence")}
                                onSelect={(field: any, value: any) =>
                                    setFieldValue(field, value)
                                }
                                displayValue="name"
                                formikValue="countryOfResidence"
                                renderOption={(props: any, option: any) => (
                                    <Box
                                        component="li"
                                        sx={{"& > img": { mr: 2, flexShrink: 0 },}}
                                        {...props}
                                    >
                                        <CountryFlag
                                            countryCode={option.code}
                                            svg
                                            style={{
                                                width: "1.5em",
                                                height: "1.5em",
                                            }}
                                        />
                                        <span>{option.name}</span>
                                    </Box>
                                )}
                            />
                            {touched.countryOfResidence &&
                                errors.countryOfResidence && (
                                    <Typography
                                        variant="subtitle2"
                                        align="left"
                                        className={classes.errorMsg}
                                    >
                                        {errors.countryOfResidence}
                                    </Typography>
                                )}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <SearchInput
                                options={countries}
                                label={t("Place of Birth")}
                                onSelect={(field: any, value: any) =>
                                    setFieldValue(field, value)
                                }
                                displayValue="name"
                                formikValue="placeOfBirth"
                                renderOption={(props: any, option: any) => (
                                    <Box
                                        component="li"
                                        sx={{
                                            "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                    >
                                        <CountryFlag
                                            countryCode={option.code}
                                            svg
                                            style={{
                                                width: "1.5em",
                                                height: "1.5em",
                                            }}
                                        />
                                        <span>{option.name}</span>
                                    </Box>
                                )}
                            />
                            {touched.placeOfBirth && errors.placeOfBirth && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.placeOfBirth}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <TextField
                                label={t("Address")}
                                variant="outlined"
                                className={classes.inputBox}
                                value={values.address}
                                onBlur={handleBlur("address")}
                                onChange={handleChange("address")}
                            />
                            {touched.address && errors.address && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.address}
                                </Typography>
                            )}
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Box sx={{ padding: "10px 0" }}>
                                        <Autocomplete
                                            disablePortal
                                            options={countries}
                                            getOptionLabel={(option: any) => `${option.phone_code} (${option.code})`}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            renderOption={(
                                                props: any,
                                                option: any
                                            ) => (
                                                <Box
                                                    component="li"
                                                    sx={{
                                                        "& > img": {
                                                            mr: 2,
                                                            flexShrink: 0,
                                                        },
                                                    }}
                                                    {...props}
                                                >
                                                    <CountryFlag
                                                        countryCode={option.code}
                                                        svg
                                                        style={{
                                                            width: "1.5em",
                                                            height: "1.5em",
                                                        }}
                                                    />
                                                    <span>{option.phone_code}</span>
                                                </Box>
                                            )}
                                            onChange={(event, value) => 
                                                setFieldValue("countryCode", value ? value.id : "")
                                            }
                                            renderInput={(params) => <TextField {...params} label={"Country Code"} />}
                                        />
                                        </Box>
                                    {touched.countryCode &&
                                        errors.countryCode && (
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className={classes.errorMsg}
                                            >
                                                {errors.countryCode}
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label={t("Mobile Number")}
                                        variant="outlined"
                                        className={classes.inputBox}
                                        value={values.mobileNumber}
                                        onBlur={handleBlur("mobileNumber")}
                                        onChange={
                                            (event) => {
                                                if (event.target.value.match(/^[0-9]*$/) && event.target.value.length <= 10) {
                                                    setFieldValue("mobileNumber", event.target.value);
                                                }
                                            }
                                        }
                                    />
                                    {touched.mobileNumber &&
                                        errors.mobileNumber && (
                                            <Typography
                                                variant="subtitle2"
                                                align="left"
                                                className={classes.errorMsg}
                                            >
                                                {errors.mobileNumber}
                                            </Typography>
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.gridItemPadding}
                        >
                            <SearchInput
                                options={incomeSources}
                                label={t("Income Source")}
                                onSelect={(field: any, value: any) =>
                                    setFieldValue(field, value)
                                }
                                displayValue="name"
                                formikValue="incomeSourece"
                            />
                            {touched.incomeSourece && errors.incomeSourece && (
                                <Typography
                                    variant="subtitle2"
                                    align="left"
                                    className={classes.errorMsg}
                                >
                                    {errors.incomeSourece}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <Box className={classes.buttonBox}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submitBtnForm}
                                    onClick={() => handleSubmit()}
                                >
                                    {t("Submit")}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Formik>
        </Box>
    );
};

export default PersonalDetailsForm;
