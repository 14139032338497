import { useEffect, useState, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSessionStorage } from "./useStorage";
import i18n from "i18next";

const LOCALIZATION_CONST = {
    LANGUAGE_KEY: "lang",
    STORAGE_KEY: "TBP-LOCALIZATION",
    DEFAULT_LOCALIZATION_DATA: {
    languageCode: "en",
  },
};

export type T_Localization = {
     languageCode: string;
};

type T_LocalizationOptions = {
    languageCode?: string;
}

export type T_UseLocalizationResult = T_Localization & {
  initializeLanguage: (langCode: string) => void;
};

export const useLocalization = (opt?: T_LocalizationOptions): T_UseLocalizationResult => {
    const location = useLocation();
    const queryParams: URLSearchParams = new URLSearchParams(location.search);
    const [localizationData, setLocalizationData] = useState<T_Localization>(
    LOCALIZATION_CONST.DEFAULT_LOCALIZATION_DATA
  );
    const localizationStorage = useSessionStorage<T_Localization>(
    LOCALIZATION_CONST.STORAGE_KEY,
    LOCALIZATION_CONST.DEFAULT_LOCALIZATION_DATA
  );
    const manualLanguageChangeRef = useRef(false);
    const initializeLanguage = useCallback(async (langCode: string) => {
    try {
      if (i18n.language !== langCode) {
        await i18n.changeLanguage(langCode);
        setLocalizationData({ languageCode: langCode });
        localizationStorage.setValue({ languageCode: langCode });
        console.log(`Language changed to ${langCode}`);
      }
    } catch (error) {
      console.error("Failed to change language:", error);
    }
  }, [localizationStorage]);
    useEffect(() => {
        if (!manualLanguageChangeRef.current) {
            const languageCodeFromUrl = queryParams.get(LOCALIZATION_CONST.LANGUAGE_KEY);
            const languageCodeFromStorage = localizationStorage.getValue().languageCode;

            const finalLanguageCode = languageCodeFromUrl
                || languageCodeFromStorage
                || LOCALIZATION_CONST.DEFAULT_LOCALIZATION_DATA.languageCode;

            if (finalLanguageCode !== localizationData.languageCode) {
                initializeLanguage(finalLanguageCode);
            }
        } else {
            manualLanguageChangeRef.current = false;
        }
}, [queryParams, localizationStorage, initializeLanguage, localizationData.languageCode]);
    const changeLanguageManually = (langCode: string) => {
        manualLanguageChangeRef.current = true;
        initializeLanguage(langCode);
    };

    return { ...localizationData, initializeLanguage: changeLanguageManually };
};