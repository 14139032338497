/**
 * @file: http-request.ts
 * @date: 26 Aug 2023

 * @description: $
 */
import {logger} from "app/config";


import {I_HttpRequestParams, I_JsonResponse} from "./Interfaces";


export class ApiResponse<TD = any> {
    private readonly response: I_JsonResponse<TD>;

    constructor({success, data, message, code}: I_JsonResponse) {
        this.response = {success, data, message, code} as I_JsonResponse<TD>;
    }

    public getJson(): I_JsonResponse<TD> {
        return this.response;
    }

    public isSuccess(): boolean {
        return (this.response?.success && this.getCode() === 200) || false;
    }

    public getData(): TD {
        return (this.response?.data || []) as TD;
    }

    public getMessage(): string {
        return this.response?.message || "";
    }

    public getCode(): number {
        return this.response?.code || 0;
    }

    public get isError(): boolean {
        return !this.isSuccess() || false;
    }

}


export class BasicHttpRequest<TD = any> {

    /** The URL to which the HTTP request will be sent */
    public readonly url: string;

    /** The HTTP method for the request (e.g., GET, POST, etc.) */
    public readonly method: string;

    /** The headers for the HTTP request */
    public readonly headers: object;

    /** The query parameters to include in the URL */
    public readonly queryString: string;

    /** The data to be sent to server */
    public readonly payload?: Array<any> | object;


    private parsedUrlQueryParams(params: any): string {
        if (Array.isArray(params)) {
            return (params.length > 0) ? `/${params.join("/")}` : ``;
        }
        return (Object.keys(params).length > 0) ? `?${(new URLSearchParams(params).toString())}` : ``;
    }

    constructor({url, method, payload, params, headers}: I_HttpRequestParams) {
        this.url = url.trim().replace(/\/$/, '');
        this.method = method;
        this.headers = {...(headers || {})};
        this.payload = ['GET', 'HEAD'].includes(method.toUpperCase()) ? undefined : (payload || {});
        this.queryString = this.parsedUrlQueryParams(params || {});

    }

    private async execute(): Promise<Response> {
        /**
         * Build the request URL
         */
        const requestUrl: string = `${this.url}${this.queryString}`;

        /**
         *  Initialize request body
         */

        const requestBody: BodyInit = JSON.stringify(this.payload);

        /**
         * Initialize request Headers
         */

        const requestHeaders: HeadersInit = {...this.headers};


        const requestOptions = {
            method: this.method,
            headers: requestHeaders,
            body: requestBody
        };
        /**
         *  Make the HTTP request using fetch and return
         */

        return fetch(requestUrl, requestOptions);
    };

    public async response(): Promise<Response> {
        return this.execute().then((response) => {
            if (response.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = "/login";
            }
            return response;
        }).catch(reason => reason);
    }


}


export class HttpRequest<TD = any> extends BasicHttpRequest {


    public async json(dataKey: string = "data"): Promise<I_JsonResponse<TD>> {
        return this.response().then(async (response): Promise<I_JsonResponse<TD>> => {
            const contentType = response.headers.get("content-type");
            const isJsonResponse = (contentType && contentType.indexOf("application/json") !== -1);
            const jsonResponse = isJsonResponse ? await response.json() : {data: await response.text()};
            return {
                success: jsonResponse?.success || response.ok,
                data: (jsonResponse?.[dataKey] ?? jsonResponse) as TD,
                code: jsonResponse?.code || response.status,
                message: jsonResponse?.message || response.statusText,
            } as I_JsonResponse<TD>;
        }).catch((error: any) => {
            return {success: false, code: 0, message: error.toString(), data: [] as TD} as I_JsonResponse<TD>;
        });
    }

    public async apiResponse(dataKey: string = "data"): Promise<ApiResponse<TD>> {
        return this.json(dataKey).then((jsonResponse: I_JsonResponse) => new ApiResponse(jsonResponse));
    }

    public async data<TData = any>(dataKey: string = "data"): Promise<TData> {
        return this.json(dataKey).then((resp: I_JsonResponse) => resp.data as TData).catch();
    }
}
