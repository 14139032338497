import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {AppProvider, Environment} from "app/common";
import {theme} from "app/styles";
import i18n from "app/config";

const App = () => {
	if (Environment.IS_DEVELOPMENT || Environment.IS_TESTING) {
		console.dir(process.env);
	}

	return <AppProvider theme={theme}></AppProvider>;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
