import React from "react";
import {useParams} from 'react-router-dom';
import {useCryptohubPaymentStyles} from "./CryptohubPaymentStyles";
import {TransactionView} from "./TransactionView";
import CryptoFormView from "./CryptoFormView";
import {useCryptohubPaymentState} from "./CryptohubPaymentHooks";
import {AmountConfirmationView} from "./AmountConfirmationView";
import {VerifyOTP} from "./VerifyOTP";
import LivelinessVerification from "./LivelinessVerification";
import {InitiateTransactionView} from "./InitiateTransactionView";
import {IntegrationView} from "../common";
import {TransactionCompletedView} from "./TransactionCompletedView";
import PersonalDetailsForm from "./PersonalDetailsForm";
import { SimpleKycVerifyOtp } from "./SimpleKycVerifyOtp";


export const CryptohubPaymentView = (): React.JSX.Element => {
    const {transactionId} = useParams();
    const {classes} = useCryptohubPaymentStyles();
    const paymentState = useCryptohubPaymentState(transactionId);

    const ActiveComponent = (): React.JSX.Element => {

        if(!transactionId) {
            return <CryptoFormView />
        }

        if (!paymentState.isConfirmed) {
            return <AmountConfirmationView {...({classes, paymentState, transactionId})} />
        }

        if (!paymentState.isOtpVerified) {
            return <VerifyOTP paymentState={paymentState} />
        }

        if(!paymentState.isSimpleKycCompleted) {
            return <PersonalDetailsForm {...({classes, paymentState})}/>
        }

        if(!paymentState.isSimpleKycOtpVerified) {
            return <SimpleKycVerifyOtp paymentState={paymentState} />
        }

        if (!paymentState.isKycCompleted) {
            return <LivelinessVerification paymentState={paymentState} />
        }

        if (!paymentState.isTransactionInitiated) {
            return <InitiateTransactionView paymentState={paymentState} />
        }

        if(paymentState.isTransactionCompleted) {
            return <TransactionCompletedView {...({classes, paymentState, transactionId})} />
        }

        if (paymentState.transactionLink){
            return <TransactionView {...({classes, paymentState, transactionId})} />
        }

        return (<></>);
    }

    return ( <IntegrationView activeComponent={ActiveComponent()} />   );
}
