import React from "react";
import { Box,  Button,  Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,  Typography } from "@mui/material";
import { pallete } from "app/styles";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import TransactionsTable from "app/components/unsorted/TransactionTable";
import { useCryptoHubDashboardStyles } from "./CryptohubStyles";
import {
  useCryptoHubDayBasedTransactionStatsRequest,
  useCryptoHubTransactionStatsRequest,
  StatsKey,
  T_CryptohubTransactionStats,
  // useCryptoHubTransactionTableRequest,
} from "./CryptoHubDashboardQueries";
import Loader from "app/components/unsorted/Loader";
import { StatsCard } from "app/components/widgets";
import {
  AccountBalance,
  AttachMoney,
  Done,
  LocalShipping,
  Pending,
} from "@mui/icons-material";
import CryptoHubTransactionTable from "./CryptoHubTransactionTable";
import axios from "axios";
import { ApiConfig } from "app/config";
import { useNotification } from "app/providers";
import { ApiRequest } from "app/utils/http";

export const tokenListIcons0 = [
  {
    id: 0,
    color: pallete.selections.green,
    icon: "assets/icons/bitcoin.svg",
    name: "USD",
    rate: "41%",
    price: "73,431",
  },
  {
    id: 1,
    color: pallete.selections.blue,
    icon: "assets/icons/eth.svg",
    name: "EUR",
    rate: "20%",
    price: "29,372",
  },
];
const pieChartData = {
  labels: ["BTC", "ETH", "XRP", "DOGE", "Other"],
  datasets: [
    {
      label: "Crypto Distribution",
      data: [73431, 29372, 17623, 14686, 11749],
      backgroundColor: [
        pallete.selections.red, // BTC
        pallete.selections.brown, // ETH
        pallete.selections.white, // XRP
        pallete.selections.green, // DOGE
        pallete.selections.pink, // Other
      ],
      borderColor: ["#FFFFFF"],
      borderWidth: 5,
      borderRadius: 15,
      hoverOffset: 4,
    },
  ],
};

const pieChartData01 = {
  labels: ["BTC", "ETH"],
  datasets: [
    {
      label: "Crypto Distribution",
      data: [40000, 60000],
      backgroundColor: [
        pallete.selections.green, // DOGE
        pallete.selections.blue, // Other
      ],
      borderColor: ["#FFFFFF"],
      borderWidth: 5,
      borderRadius: 15,
      hoverOffset: 4,
    },
  ],
};

const pieChartOptions = {
  cutout: "80%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
    },
    centerText: {
      text: `≈$146863`, // Text you want to display
    },
  },
  maintainAspectRatio: false,
};

function getDayFromDate(dateString: string) {
  const date = new Date(dateString);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return daysOfWeek[date.getDay()];
}

const getDaysStatData = (daysStats: any) => {
  return Object.keys(daysStats).map((key) => {
    return {
      day: key,
      dayShort: getDayFromDate(key)?.slice(0, 3),
      ...daysStats[key],
    };
  });
};

const DaysStatToolTipBox = ({ title, value }: any) => {
  const { classes } = useCryptoHubDashboardStyles();
  return (
    <Box className={classes.tooltipBox}>
      <Typography
        sx={{ fontWeight: 600, paddingRight: "20px" }}
        variant="body1"
      >
        {title}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

const DaysStatToolTip = ({ active, payload, label }: any) => {
  const { classes } = useCryptoHubDashboardStyles();
  if (active && payload && payload.length) {
    return (
      <Card className={classes.cardTooltip}>
        {StatsKey.map((key, index) => {
          return (
            <DaysStatToolTipBox
              key={index}
              title={key}
              value={payload[0].payload[key]}
            />
          );
        })}
      </Card>
    );
  }

  return null;
};

const CryptoHubDashboard = () => {
  const { classes } = useCryptoHubDashboardStyles();
  const stats = useCryptoHubTransactionStatsRequest();
  const daysStat = useCryptoHubDayBasedTransactionStatsRequest(7);
  const notification = useNotification();
  const [modalState, setModalState] = React.useState(false);

  const daysStatsData = getDaysStatData(daysStat.result || {});
  const [visibleStats, setVisibleStats] = React.useState(StatsKey);

  const StatsCardData = {
    Total: {
      title: "Total",
      icon: <AttachMoney className={classes.icon} />,
      color: "#7F56D9",
    },
    "Payment Pending": {
      title: "Payment Pending",
      icon: <Pending className={classes.icon} />,
      color: "#FF9800",
    },
    "Payment Received": {
      title: "Payment Received",
      icon: <Done className={classes.icon} />,
      color: "#4CAF50",
    },
    "Sent To Merchant": {
      title: "Sent To Merchant",
      icon: <LocalShipping className={classes.icon} />,
      color: "#2196F3",
    },
    "Settled With Merchant": {
      title: "Settled With Merchant",
      icon: <AccountBalance className={classes.icon} />,
      color: "#673AB7",
    },
  };

  if (stats.isLoading) {
    return <Loader isLoading={true} />;
  }

  const handlePaymentSettlement = () => {
    ApiRequest.Request<any>(
      {url: ApiConfig.create("user/request-settle/crypto-hub").url, 
        method: "GET"}
      ).apiResponse().then((data) => {
        if(data.getCode() === 200){
          notification.success("Settlement request sent successfully");
        }
        else{
          notification.error(data.getMessage());
        }
      });
  }

  return (
    <>
      <Dialog open={modalState} onClose={() => {
        setModalState(false);
      }}>
        <DialogTitle id="alert-dialog-title">Request For Settlement</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to settle the completed transactions?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={
            () => {
              setModalState(false);
            }
          }>Disagree</Button>
          <Button onClick={
            () => {
              setModalState(false);
              handlePaymentSettlement();
            }
          } autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.mainBox}>
        <Grid container justifyContent={"space-between"} padding={"10px"}>
          <Grid
            className={classes.StatsCardContainer}
            container
            spacing={1}
            justifyContent="space-between"
          >
            {StatsKey.map((key, index) => (
              <Grid item xs={12} sm={6} md={2.3}>
                <StatsCard
                  key={index}
                  title={StatsCardData[key].title}
                  value={stats.result?.[key] || 0}
                  color={StatsCardData[key].color}
                  icon={StatsCardData[key].icon}
                />
              </Grid>
            ))}
          </Grid>
          {/* <Grid item md={6}>
              <Card className={classes.commonCards}>
                <Box className={classes.commonCardsHBox}>
                  <Typography variant="h6" color="primary">
                    Balance, $
                  </Typography>
                  <Box>
                    <Button
                      endIcon={
                        <Box
                          component="img"
                          // className={classes.endIconBox}
                          src="assets/icons/downArrow.svg"
                        />
                      }
                      startIcon={
                        <Box
                          component="img"
                          className={classes.startIconBoxB}
                          src="assets/icons/doller2.svg"
                        />
                      }
                      className={classes.iconsBtn}
                    >
                      USD
                    </Button>
                  </Box>
                </Box>
                <Grid container>
                  <Grid item md={6}>
                    <DynamicPie
                      pieChartData={pieChartData01}
                      pieChartOptions={pieChartOptions}
                      height="300px"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <DynamicPie
                      pieChartData={pieChartData}
                      pieChartOptions={pieChartOptions}
                      height="300px"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid> */}
          <Grid item md={12} className={classes.gridItems}>
            <Card className={classes.commonCards}>
              <Box className={classes.commonCardsHBox}>
                <Typography variant="h6" color="primary">
                  Weekly total IN by day
                </Typography>
                <Box>
                  {/* <Button
                      endIcon={
                        <Box
                          component="img"
                          // className={classes.endIconBox}
                          src="assets/icons/downArrow.svg"
                        />
                      }
                      startIcon={
                        <Box
                          component="img"
                          className={classes.startIconBox}
                          src="assets/icons/calendar.svg"
                        />
                      }
                      className={classes.iconsBtn}
                    >
                      Last Week
                    </Button> */}
                </Box>
              </Box>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={daysStatsData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="dayShort" />
                  <YAxis />
                  <Tooltip content={<DaysStatToolTip />} />
                  <Legend
                    onClick={(data) => {
                      const key =
                        data.dataKey as keyof T_CryptohubTransactionStats;
                      if (visibleStats.includes(key)) {
                        setVisibleStats(
                          visibleStats.filter((key) => key !== data.dataKey)
                        );
                      } else {
                        setVisibleStats([...visibleStats, key]);
                      }
                    }}
                  />
                  {StatsKey.map((key, index) => (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={key}
                      stroke={StatsCardData[key].color}
                      activeDot={{ r: 8 }}
                      hide={!visibleStats.includes(key)}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>{" "}
            </Card>
          </Grid>
          <Grid item md={12} className={classes.gridItems}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop:"20px" }} width={"100%"}>
              <Button variant="contained" color="primary" sx={{
                height: '50px',
              }}
              onClick={() => {
                setModalState(true);
              }}
              >
                Request For Settlement
              </Button>
            </Box>
          </Grid>
          <Grid item md={12} className={classes.gridItems}>
            <CryptoHubTransactionTable />
          </Grid>
          
        </Grid>
      </Box>
    </>
  );
};

export default CryptoHubDashboard;
