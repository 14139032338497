import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import {
    T_Asset,
    useAssetsList,
    useCryptohubTransactionRequest,
    useCurrencyList,
    useSettleCryptohubPaymentRequest,
} from "./CryptohubPaymentQueries";
import { CopyToClipboardButton } from "app/components/inputs/buttons";
import Loader from "app/components/unsorted/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useCryptohubPaymentStyles } from "./CryptohubPaymentStyles";
import { IntegrationView } from "../common";
import { useLoader, useNotification } from "app/providers";
import { redirectTo } from "app/utils";
import { ApiConfig } from "app/config";
import { RQ } from "app/hooks";

export const CryptohubSettlement = () => {
    const { transactionId } = useParams();
    const { classes } = useCryptohubPaymentStyles();
    const transactionRequest = useCryptohubTransactionRequest(
        transactionId || ""
    );
    const loader = useLoader();
    const useSettleCryptohubPaymentRequestQuery = useSettleCryptohubPaymentRequest();
    const notification = useNotification();
    const navigate = useNavigate();
    const [transactionReqId, setTransactionReqId] = useState(null);

    const assetsList = useAssetsList();

    const getAssetSymbol = (asset: string) => {
        let assetSymbol = "";

        if (assetsList.result) {
            const assetObj = assetsList.result.find(
                (assetObj: T_Asset) => assetObj.fireblocks_asset_id === asset
            ) as T_Asset;
            if (assetObj) assetSymbol = assetObj.acts_as;
        }

        return assetSymbol;
    };

    const apiConfig = ApiConfig.create(
        'crypto-hub-transaction', ['crypto-hub-transaction'],
    );

    const transactionPostRequest = RQ.usePostRequest({
        url: apiConfig.url,
        mutationKey: apiConfig.key,
    });

    useEffect(() => {
        transactionPostRequest.EXECUTE({
            payload:{request_uid:transactionId},
            onSuccessFn: (data, message) => {
                setTransactionReqId(data.transaction_id);
            },
            onErrorFn: (data, message) => {
                notification.error(message);
            }
        })
    }, []);

    const handleOnSettlePayment = async () => {
        loader.show();
        const payload = {
            "pk": `${transactionReqId}`,
            "transactions": []
        };
        await useSettleCryptohubPaymentRequestQuery.EXECUTE({
            payload,
            onSuccessFn: (data, message): void => {
                redirectTo({url: data.redirect_url});
            },
            onErrorFn: (error, message): void => notification.error(message),
        }).finally(() => {
            loader.hide();
        });
    };

    const handleMakePayment = async () => {
        console.log(transactionId);
        navigate(`/cryptohub-payment/${transactionId}`);
    }

    if (assetsList.isResolving || transactionRequest.isResolving || transactionPostRequest.isPending) {
        return <Loader isLoading={true} />;
    }

    return (
        <IntegrationView
            activeComponent={
                <Box className={classes.mainCard}>
                    <Box className={classes.innerCard}>
                        <Box className={classes.midBoxC1}>
                            <Box className={classes.cryptoBox}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    Total Asset to be sent :{" "}
                                </Typography>
                                <Typography variant="h5">
                                    {transactionRequest.result.asset_quantity}
                                </Typography>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    {getAssetSymbol(
                                        transactionRequest.result
                                            .fireblocks_asset_id
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.midBoxC1}>
                            <Box className={classes.cryptoBox}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    Asset already sent :{" "}
                                </Typography>
                                <Typography variant="h5">
                                    {
                                        transactionRequest.result
                                            .received_quantity
                                    }
                                </Typography>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    {getAssetSymbol(
                                        transactionRequest.result
                                            .fireblocks_asset_id
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.midBoxC1}>
                            <Box className={classes.cryptoBox}>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    Remaining Asset to be sent :{" "}
                                </Typography>
                                <Typography variant="h5">
                                    {transactionRequest.result.asset_quantity -
                                        transactionRequest.result
                                            .received_quantity}
                                </Typography>
                                <Typography variant="h5" fontWeight={"bold"}>
                                    {getAssetSymbol(
                                        transactionRequest.result
                                            .fireblocks_asset_id
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                gap: "10px",
                            }}
                        >
                            <Button
                                className={classes.settlePaymentButton}
                                onClick={handleOnSettlePayment}
                            >
                                Settle Payment
                            </Button>
                            <Button
                                className={classes.settlePaymentButton}
                                onClick={handleMakePayment}
                            >
                                Pay
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }
        />
    );
};
